<template>
  <BModal
    id="modalDetail"
    ref="modalDetail"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @shown="show()"
    @hidden="resetState()"
  >
    <validation-observer ref="formRules"
        v-slot="{ invalid }">
      <div class="text-left my-1 d-flex flex-column gap-15">
        <div class="font-bold text-12 text-black">
          {{initialName ? 'Ubah Posisi' : 'Tambah Posisi'}}
        </div>
        <b-col md="12" class="p-0">
          <b-form-group
            label="Nama Divisi"
            label-class="text-8 font-bold text-black"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Divisi"
              rules="required"
            >
              <v-select
                class="w-100"
                placeholder="Pilih Divisi"
                :label="'division_name'"
                :clearable="true"
                :options="listDivision"
                :reduce="division => division.id"
                v-model="divisionId"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12" class="p-0 mb-5">
          <b-form-group
            label="Nama Posisi"
            label-class="text-8 font-bold text-black"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Posisi"
              :rules="{ min: 3, required: true, max: 45 }"
            >
              <b-form-input
                v-model="name"
                placeholder="Masukkan Nama"
                :state="errors.length > 0 || submitErrors.name ? false : null"
              />
              <small class="text-danger">{{
                errors[0] || submitErrors.name
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <div class="d-flex gap-12 justify-center">
          <button
            size="md"
            variant="outline-primary"
            class="w-100 button-secondary"
            @click="$bvModal.hide('modalDetail')"
          >
            Batal
          </button>
          <button
            :disabled="invalid || loadingSubmit"
            class="w-100 button-primary"
            @click="submit"
          >
            <BSpinner v-if="loadingSubmit" small />
            Submit
          </button>
        </div>
      </div>
    </validation-observer>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import { alertError, alertSuccess } from '@toast'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    initialName: {
      type: String,
    },
    idItem: {
      type: Number,
    },
    initialDivisionId: {
      type: Number,
    },
    initialDivisionName: {
      type: String,
    },
  },
  data() {
    return {
      required,
      min,
      max,

      loading: false,
      loadingSubmit: false,
      name: '',
      submitErrors: {},
      listDivision: [],
      divisionId: null,

      alertError,
      alertSuccess,
    }
  },
  beforeMount() {
    this.getListDivision()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        name: this.name,
        division_id: Number.isInteger(this.divisionId) ? this.divisionId : this.initialDivisionId,
      }

      if (!this.initialName) {
        const url = 'v1/positions/store'
        await komtimAxiosIns
          .post(url, data)
          .then(() => {
            const text = 'Berhasil menambahkan data'
            this.alertSuccess(text)
            this.loadingSubmit = false

            this.$bvModal.hide('modalDetail')
            this.$emit('updated')
          })
          .catch(error => {
            this.loadingSubmit = false
            this.alertError(error)

            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(error.response.data.data).map(
                  ([key, value]) => [key, value[0]],
                ),
              )
            }
          })
      } else {
        const url = `v1/positions/${this.idItem}/update`
        await komtimAxiosIns
          .put(url, data)
          .then(() => {
            const text = 'Berhasil mengubah data'
            this.alertSuccess(text)
            this.loadingSubmit = false

            this.$bvModal.hide('modalDetail')
            this.$emit('updated')
          })
          .catch(error => {
            this.loadingSubmit = false
            this.alertError(error)

            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(error.response.data.data).map(
                  ([key, value]) => [key, value[0]],
                ),
              )
            }
          })
      }
    },
    async getListDivision() {
      this.loading = true
      this.offset = 0
      const params = `status=${true}`
      const url = `v1/divisions/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listDivision = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    show() {
      this.name = this.initialName
      this.divisionId = this.initialDivisionName
    },
    resetState() {
      this.name = ''
      this.divisionId = null
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Index.scss';
</style>
