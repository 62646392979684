// eslint-disable-next-line import/prefer-default-export
export const tableBisnis = [
  {
    key: 'position_name',
    label: 'Nama Posisi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]
